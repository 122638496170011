// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  margin-left: 0px;
}

.history-logo {
  height: 50px;
  width: 50px;
  transform: translate(-24px);
}

.main-logo {
  height: 120px;
  width: 120px;
  transform: translate(0px);
}

.profile-logo {
  height: 50px;
  width: 50px;
  transform: translate(24px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/components/NavBar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".logo-container {\n  margin-left: 0px;\n}\n\n.history-logo {\n  height: 50px;\n  width: 50px;\n  transform: translate(-24px);\n}\n\n.main-logo {\n  height: 120px;\n  width: 120px;\n  transform: translate(0px);\n}\n\n.profile-logo {\n  height: 50px;\n  width: 50px;\n  transform: translate(24px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
