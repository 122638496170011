// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .login__google {
    background-color: #4285f4;
  }
  .login div {
    margin-top: 7px;
  }`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;EACf;EACA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,eAAe;EACjB","sourcesContent":[".login {\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .login__container {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    background-color: #dcdcdc;\n    padding: 30px;\n  }\n  .login__textBox {\n    padding: 10px;\n    font-size: 18px;\n    margin-bottom: 10px;\n  }\n  .login__btn {\n    padding: 10px;\n    font-size: 18px;\n    margin-bottom: 10px;\n    border: none;\n    color: white;\n    background-color: black;\n  }\n  .login__google {\n    background-color: #4285f4;\n  }\n  .login div {\n    margin-top: 7px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
